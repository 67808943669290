<template>
  <content class="cardapio">
    <div class="GrupoAtalho">
      <div class="menu">
        <div
          class="atalho"
          v-for="grupo in produtos"
          :key="`atalho${grupo.Grupo}`"
          @click="selectGroup(grupo.cod_grupo)"
          :class="{ selecionado: grupo.cod_grupo == grupoSelecionado }"
        >
          <i class="far fa-dot-circle" style="margin-bottom:5px;" />
          {{ grupo.Grupo }}
        </div>
      </div>
    </div>
    <div class="lista" :class="{ reduzida: selected.length > 0 }">
      <div
        class="grupo"
        v-for="grupo in produtos"
        :key="grupo.Grupo"
        :ref="`gp_${grupo.cod_grupo}`"
      >
        {{ grupo.Grupo }}
        <small class="float-right"
          ><small>({{ grupo.produtos.length }})</small></small
        >
        <div class="produtos">
          <div
            class="produto"
            :class="
              `${canSelect ? 'selectable ' : ' '}` +
                `${selected.indexOf(prod) >= 0 ? 'selected ' : ' '}`
            "
            @click="selProd(prod)"
            v-for="prod in grupo.produtos"
            :key="prod.id"
          >
            <div class="nome">
              {{ prod.cod_produto }}
              <span v-if="prod.precos && prod.precos.length > 0">
                <br />
              </span>
              <span v-if="!prod.precos || prod.precos.length <= 0">
                -
              </span>
              {{ prod.nome }}
            </div>
            <div class="preco" v-if="!prod.precos || prod.precos.length <= 0">
              R$ {{ prod.preco_venda | currency }}
            </div>
            <div
              class="preco"
              v-for="preco of prod.precos"
              :key="prod.cod_produto + preco.cod_tipo"
            >
              <small
                >{{ preco.tipo }}<br />
                <small> R$ {{ preco.inteira | currency }} </small></small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pedido" v-if="selected && selected.length > 0">
      <div v-if="selectedMultiple" class="pizza">
        <b>Pizza</b>

        <div class="tamanho">
          <b-button v-b-toggle.collapse-1-inner size="sm" block>
            <i class="fa fa-expand" /> {{ tamanhoSelecionado.tipo }}</b-button
          >
          <b-collapse id="collapse-1-inner" v-model="openTamanho" class="mt-2">
            <b-card>
              <b-form-radio-group
                id="btn-radios-2"
                v-model="tamanhoSelecionado"
                :options="
                  $store.state.tipos_preco.map(it => {
                    return { value: it, text: it.tipo };
                  })
                "
                button-variant="outline-primary"
                size="sm"
                name="radio-btn-outline"
                buttons
                fill
                :stacked="$store.state.isMobile"
              ></b-form-radio-group>
            </b-card>
          </b-collapse>
        </div>

        <div class="itens">
          <div class="item" v-for="prod in selected" :key="prod.cod_produto">
            <div class="txt">
              {{ selected.length > 1 ? "1/2" : "" }}
              {{ prod.nome }}
            </div>

            <div class="delete" @click="configProd(prod)">
              <i class="fa fa-cogs" />
            </div>
            <div class="delete" @click="delSelected(prod)">
              <i class="fa fa-trash" />
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </content>
</template>

<script>
export default {
  components: {},
  props: {
    canSelect: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.carregarCardapio();
  },
  data() {
    return {
      produtos: [],
      grupoSelecionado: null,
      selected: [],
      selectedMultiple: false,
      cardapio: this.$store.state.cardapio,
      tamanhoSelecionado: this.$store.state.tipos_preco[0],
      openTamanho: false
    };
  },
  watch: {
    tamanhoSelecionado: function() {
      this.openTamanho = false;
    }
  },
  computed: {},
  methods: {
    carregarCardapio() {
      this.produtos = this.$store.state.cardapio.filter(x=>x.produtos.filter(p=>p.tipo_venda && p.tipo_venda.includes('MESA')).length>0).map(card=>{
        return {...card, produtos: card.produtos.filter(x=>x.tipo_venda && x.tipo_venda.includes('MESA'))}
      });
      // console.log('PRODUTOS',this.produtos);
    },
    selectGroup(gp) {
      this.grupoSelecionado = gp;
      this.$refs[`gp_${gp}`][0].offsetParent.scrollTop = this.$refs[
        `gp_${gp}`
      ][0].offsetTop;
      // console.log(this.$refs[`gp_${gp}`][0].offsetTop);
    },
    selProd(prod) {
      if (!this.canSelect) {
        return;
      }
      // console.log(prod);

      if (this.selected.filter(x => x == prod).length > 0) {
        this.selected = this.selected.filter(x => x != prod);
      } else {
        this.selectedMultiple =
          prod.tabela_preco && prod.tabela_preco.length > 0;
        if (this.selectedMultiple) {
          this.selected = this.selected.filter(
            x => x.tabela_preco && x.tabela_preco.length > 0
          );
        } else {
          this.selected = this.selected.filter(
            x => !x.tabela_preco || x.tabela_preco.length <= 0
          );
        }
        if (
          (this.selectedMultiple && this.selected.length <= 1) ||
          this.selected.length <= 0
        ) {
          this.selected.push(prod);
        } else {
          this.selected[this.selected.length - 1] = prod;
        }
        // console.log("selected", this.selected);
      }
      this.$forceUpdate();
    },
    delSelected(prod) {
      this.selected = this.selected.filter(x => x != prod);
      this.$forceUpdate();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mesas.scss";
</style>